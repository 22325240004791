import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Api } from "../../contracts/classes/api.class";
import { IResponse } from "../../contracts/models/response.model";
import { Audience, CreateAudienceDTO, UpdateAudienceDTO } from "../../contracts/models/audiences.model";

@Injectable({
  providedIn: "root"
})
export class AudienceService extends Api {

  getAll(): Observable<IResponse<Audience[]>> {
    return this.http.get<IResponse<Audience[]>>(`${this.url}/audienciaMantenimiento`);
  }

  create(createAudienceDTO: CreateAudienceDTO): Observable<IResponse<Audience>> {
    return this.http.post<IResponse<Audience>>(`${this.url}/audienciaMantenimiento`, createAudienceDTO);
  }

  update(updateAudienceDTO: UpdateAudienceDTO): Observable<IResponse<Audience>> {
    return this.http.put<IResponse<Audience>>(`${this.url}/audienciaMantenimiento/${updateAudienceDTO.id}`, updateAudienceDTO);
  }

  delete(id: number): Observable<IResponse<Audience>> {
    return this.http.delete<IResponse<Audience>>(`${this.url}/audienciaMantenimiento/${id}`);
  }
}