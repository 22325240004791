import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Api } from "../../contracts/classes/api.class";
import { CreateObjectiveDTO, Objective, UpdateObjectiveDTO } from "../../contracts/models/objectives.model";
import { IResponse } from "../../contracts/models/response.model";

@Injectable({
  providedIn: "root"
})
export class ObjectivesService extends Api {

  getAll(): Observable<IResponse<Objective[]>> {
    return this.http.get<IResponse<Objective[]>>(`${this.url}/objetivoMantenimiento`);
  }

  create(createObjectiveDTO: CreateObjectiveDTO): Observable<IResponse<Objective>> {
    return this.http.post<IResponse<Objective>>(`${this.url}/objetivoMantenimiento`, createObjectiveDTO);
  }

  update(updateObjectiveDTO: UpdateObjectiveDTO): Observable<IResponse<Objective>> {
    return this.http.put<IResponse<Objective>>(`${this.url}/objetivoMantenimiento/${updateObjectiveDTO.id}`, updateObjectiveDTO);
  }

  delete(id: number): Observable<IResponse<Objective>> {
    return this.http.delete<IResponse<Objective>>(`${this.url}/objetivoMantenimiento/${id}`);
  }
}