import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Api } from "../../contracts/classes/api.class";
import { CreateTypeNoteDTO, TypeNote, UpdateTypeNoteDTO } from "../../contracts/models/type-note.model";
import { IResponse } from "../../contracts/models/response.model";

@Injectable({
  providedIn: "root"
})
export class TypeNoteService extends Api {

  getAll(): Observable<IResponse<TypeNote[]>> {
    return this.http.get<IResponse<TypeNote[]>>(`${this.url}/tipoNotaMantenimiento`);
  }

  create(createTypeNoteDTO: CreateTypeNoteDTO): Observable<IResponse<TypeNote>> {
    return this.http.post<IResponse<TypeNote>>(`${this.url}/tipoNotaMantenimiento`, createTypeNoteDTO);
  }

  update(updateTypeNoteDTO: UpdateTypeNoteDTO): Observable<IResponse<TypeNote>> {
    return this.http.put<IResponse<TypeNote>>(`${this.url}/tipoNotaMantenimiento/${updateTypeNoteDTO.id}`, updateTypeNoteDTO);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/tipoNotaMantenimiento/${id}`);
  }

}