import { Component, Inject, OnInit, OnDestroy, inject } from '@angular/core';
import { FormControl, NonNullableFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaPlanService } from 'src/app/core/services/api/media-plan.service';
import { PeopleService } from 'src/app/core/services/api/people.service';
import { Subject } from 'rxjs';
import { takeUntil, tap, switchMap, filter } from 'rxjs/operators';
import { typesNotes, stages, tiers } from 'app/shared/general/datageneral';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { ProgramService  } from 'src/app/core/services/api/program.service';
import { ContactBitacoraForm } from 'src/app/pages/media-plan/media-plan-form/interfaces/contact-bitacora-form.interface';
import { Select, MediaProgramSelect, PersonaSelect, MediaPlatformSelect } from '../../core/contracts/models/form-input.model';
import { JournalistFormService, ModelFormGroup } from '../../core/services/journalist-form.service';
import { DetailsForm } from '../../pages/media-plan/media-plan-form/interfaces/contact-bitacora-form.interface';
import { TypeNotesService } from 'src/app/core/services/api/type-notes.service';
import { StagesService } from 'src/app/core/services/api/stages.service';
import { TiersService } from 'src/app/core/services/api/tiers.service';


export interface DataInput {
    campaign_id: number, 
    form: ContactBitacoraForm,
    details: DetailsForm | null,
}
@Component({
  selector: 'journalist-form',
  templateUrl: './journalist-form.component.html'
})
export class JournalistFormComponent implements OnInit, OnDestroy {

    public myForm: ModelFormGroup<ContactBitacoraForm>;
    public platform_filter= new FormControl<string>('');
    public contact_program_filter = new FormControl<string>('');

    typesNotesService = inject(TypeNotesService);
    stagesService = inject(StagesService);
    tiersService = inject(TiersService);

    public typesNotes$ = this.typesNotesService.getAll();
    public stages$ = this.stagesService.getAll();
    public tiers$ = this.tiersService.getAll();

    public contact_Select: Select;
    public spokesmens_select: PersonaSelect[] = [];
    public contact_program_select: MediaProgramSelect[] = [];
    public media_platform_select: MediaPlatformSelect[] = [];
    public loading = {medio_programa: false, plataforma: false, voceros: false};
    protected _onDestroy = new Subject<void>();
      
    constructor(
        private dialogRef                    : MatDialogRef<JournalistFormComponent>,
        private formBuilder                  : NonNullableFormBuilder,
        private service                      : JournalistFormService,
        private peopleService                : PeopleService,
        private mediaPlanService             : MediaPlanService,
        private programService               : ProgramService,
        private swal                         : Swal2,
        @Inject(MAT_DIALOG_DATA) public data : DataInput,
    ) {
        this.myForm = this.service.createForm( this.data.form );
    }

    ngOnInit(): void {
        
        //*? Voceros *//
        this.service.getSpeakersSelectByCampaignId(this.data.campaign_id)
            .pipe( 
                tap( () => {
                    this.loading.voceros = true;
                    this.voceros.disable();
                })
            )
            .subscribe(( voceros ) => {
                if( voceros.length > 0 ) {
                    this.spokesmens_select = voceros;
                    this.voceros.enable();
                }
                this.loading.voceros = false
            });

        //*? Contactos *//
        this.contacto.valueChanges
            .pipe( 
                filter( ( contact_id ) => contact_id > 0 ),
                takeUntil( this._onDestroy ),
                tap( () => {
                    this.loading.medio_programa = true;
                    this.medio_programa.reset();
                    this.medio_programa.disable();
                }),
                switchMap( (contact_id ) => this.service.getProgramSelectByContactId( contact_id ) ),
            )
            .subscribe( programas => {
                if( programas.length > 0 ) {
                    this.contact_program_select = programas;
                    this.medio_programa.enable();
                }
                this.loading.medio_programa = false
            })

        //*? Medio:Programa *//
        this.medio_programa.valueChanges
            .pipe(
                takeUntil( this._onDestroy ),
                filter( program_id => program_id > 0 ),
                tap( () => {
                    this.loading.plataforma = true
                    this.plataformas.reset();
                    this.plataformas.disable();
                }),
                switchMap( program_id => this.service.getPlatformSelectByContactAndProgram( this.contacto.value, program_id ) ),
            )
            .subscribe( plataformas => {
                if( plataformas.length > 0 ) {
                    this.media_platform_select = plataformas;
                    this.plataformas.enable();
                }
                this.loading.plataforma = false
            });    
    }

    public ngOnDestroy(): void {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

    public selectedId( contact: Select ): void {
        this.contact_Select = contact;
        this.contacto.setValue( contact.id );
    }

    public submitForm(): void {
        const form_data = this.myForm.value as ContactBitacoraForm;
        const medio_programa = this.contact_program_select.find( select => select.programa_id === form_data.contact_program );
        //TODO: REFACTORIZAR API DE SISTEMA EXPERTO >> CAMBIAR EL TIPADO DE MediaPlatformSE A MediaPlatformSelect
        const plataformas = this.media_platform_select
            .filter( selects => form_data.media_platforms.includes( selects.id ) )
            .map( plat => ({
                id: plat.id,
                alcance: 0,
                valor: plat.medio_plat_valor,
                plataforma_descripcion: plat.plat_clasif_desc,
                plataforma: plat.plat_desc,
            }));
        const details = {
            name: this.contact_Select.label,
            medio: medio_programa.medio,
            programa: medio_programa.programa,
            plataformas: plataformas

        }
        this.dialogRef.close( { form_data, details } ) 
    }


    //* Getters And Setters *//
        //? Formulario
        public get contacto(): FormControl<number> {
            return this.myForm.get('contact') as FormControl;
        }

        public get medio_programa(): FormControl<number> {
            return this.myForm.get('contact_program') as FormControl;
        }

        public get plataformas(): FormControl<number> {
            return this.myForm.get('media_platforms') as FormControl;
        }

        public get voceros(): FormControl<number> {
            return this.myForm.get('spokesmens') as FormControl;
        }

        //? Hints
        public get voceros_hint(): boolean {
            return !this.loading_form && this.voceros.disabled;
        }

        public get medio_programa_hint():boolean {
            return !this.loading_form && this.medio_programa.disabled && this.contacto.value > 0;
        }

        public get plataformas_hint(): boolean {
            return !this.loading_form && this.plataformas.disabled && this.medio_programa.value > 0;
        }
        //? Loading
        public get loading_form(): boolean {
            return this.loading.voceros || this.loading.plataforma || this.loading.medio_programa;
        }

    //* Getters And Setters *//


//   isFormValid(): boolean {
//       return this.contactBitacoraForm.invalid;
//   }

//   addContact() {
//       this.contactBitacoraForm.markAsPristine();
//       this.contactBitacoraForm.markAsUntouched();
//       this.formLoading = true;

//       let newContact:DpmDTO = {
//           idPlanMedio: this.data.id,
//           idsMedioPlataforma: this.contactBitacoraForm.get('platformIds').value,
//           muestrasRegistradas: this.contactBitacoraForm.get('muestrasRegistradas').value,
//           muestrasEnviadas: this.contactBitacoraForm.get('muestrasEnviadas').value,
//           muestrasVerificadas: this.contactBitacoraForm.get('muestrasVerificadas').value,
//           tipoNota: this.contactBitacoraForm.get('tipoNota').value,
//           tipoEtapa: this.contactBitacoraForm.get('tipoEtapa').value,
//           tipoTier: this.contactBitacoraForm.get('tipoTier').value,
//           voceros: this.contactBitacoraForm.get('spokesmenIds').value,
//           observacion: this.contactBitacoraForm.get('observation').value
//       }

//       for(let prog of this.programas){
//           if(prog.programa_id==this.contactBitacoraForm.get('programId').value){
//               newContact['idProgramaContacto'] = prog.programaContacto_id
//           }
//       }

//       if(this.data.newMediaPlan) {
//           this.swal.showToast('Contacto agregado exitosamente!!', 'success')
//           this.dialogRef.close({
//               stage: this.data.stage, 
//               contact: this.contacts.find(c => c.id == this.idContact),
//               form: newContact, 
//               programa: this.programas.find(pr => pr.programaContacto_id == newContact['idProgramaContacto']),
//               plataformas: this.plataformas.filter(pl => newContact.idsMedioPlataforma.find(p => pl.id == p))
//           })
//       } 

//       else this.createContactDetail(newContact)
//   }

//   editContact() {

//       const medioPrograma = this.programas.find(pr => pr.programa_id == this.contactBitacoraForm.get('programId').value)
//       let contact: DpmUpdateDTO = {
//           idPlanMedio:         this.data.id,  //! ? revisar en editar contacto - plan nuevo
//           idsMedioPlataforma:  this.contactBitacoraForm.get('platformIds').value,
//           idProgramaContacto:  medioPrograma.programaContacto_id,
//           muestrasRegistradas: this.contactBitacoraForm.get('muestrasRegistradas').value,
//           muestrasEnviadas:    this.contactBitacoraForm.get('muestrasEnviadas').value,
//           muestrasVerificadas: this.contactBitacoraForm.get('muestrasVerificadas').value,
//           tipoNota:            this.contactBitacoraForm.get('tipoNota').value,
//           tipoEtapa:           this.contactBitacoraForm.get('tipoEtapa').value,
//           tipoTier:            this.contactBitacoraForm.get('tipoTier').value,
//           voceros:             this.contactBitacoraForm.get('spokesmenIds').value,
//           observacion:         this.contactBitacoraForm.get('observation').value
//       };

//       if(this.data.newMediaPlan) { 
//           this.swal.showToast('Contacto editado exitosamente!!', 'success')
//           this.dialogRef.close({
//               id: this.data.id, //* Usado para identificar contactos en Nuevo Plan de Medio = index->row media-plan-form-component.ts 453
//               stage: this.data.stage, 
//               contact: this.contacts.find(c => c.id == this.idContact),
//               form: contact, 
//               programa: this.programas.find(pr => pr.programaContacto_id == contact['idProgramaContacto']),
//               plataformas: this.plataformas.filter(pl => contact.idsMedioPlataforma.find(p => pl.id == p))}) 
//       } 
//       else this.editContactDetail(contact, this.data.id) //! (contact, this.data.row.id)
      
//   }

//   createContactDetail(newContact:DpmDTO) {
//       this.mediaPlanService.createDetail(newContact).subscribe({
//           next: (response: any) => {
//               this.swal.showToast(response.message, 'success')
//               this.dialogRef.close({action: true})
//           },
//           error: (error) => { 
//               this.swal.showErrorAlert(error.error.message, `Error ${error.status}`)
//               this.formLoading = false;
//           }
//       });
//   }

//   editContactDetail(contact:DpmUpdateDTO, id:number){
//       this.mediaPlanService.editDetail(contact, id).subscribe({
//           next: (response: any) => { 
//               this.swal.showToast(response.message, 'success')
//               this.dialogRef.close({action: true, contact})
//           },
//           error:(error) => { 
//               this.swal.showErrorAlert(error.error.message, `Error ${error.status}`)
//               this.formLoading = false;
//           }
//       }); 
//   }

//   remove(id): void {
//       let preSelects = [...this.contactBitacoraForm.get('platformIds').value]

//       const index = preSelects.indexOf(id);
//       if (index != -1) {
//           const filteredOptions = [...preSelects].filter(op => op != id);
//           this.contactBitacoraForm.get('platformIds').patchValue(filteredOptions);
//       }
//   }

  
}