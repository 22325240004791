import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { resultadoResponseAPI } from 'src/app/core/contracts/models/impactos';
import { DynamicSelectService } from 'src/app/core/services/api/dynamic-select.service';
import { ResultadosPlataformasService } from 'src/app/core/services/api/resultadosPlatafoms.service';
import { stages, tiers, typesNotes } from 'app/shared/general/datageneral';
import { map, zip } from 'rxjs';
import { TypeNotesService } from 'src/app/core/services/api/type-notes.service';
import { StagesService } from 'src/app/core/services/api/stages.service';
import { TiersService } from 'src/app/core/services/api/tiers.service';
@Component({
  selector: 'app-view-impacto',
  templateUrl: './view-impacto.component.html',
  styleUrls: ['./view-impacto.component.scss']
})
export class ViewImpactoComponent implements OnInit{

  nombre = '';
  medio = '';
  programa = '';
  plataformas: any[];
  voceros: string[];
  tipoNota = '';
  etapa = '';
  tier = '';

  muestras_registradas = 0;
  muestras_enviadas = 0;
  muestras_verificadas = 0;

  lastBitacora = 0;
  observacion = '';
  tipo_comunicacion = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private resultadoService : ResultadosPlataformasService,
    private dynamicSelectService: DynamicSelectService,
    private typeNotes: TypeNotesService,
    private stages: StagesService,
    private tiers: TiersService
  ) { 
    this.nombre = this.data.contactName;
    this.medio = this.data.programa_contacto.programa.medio.nombre;
    this.programa = this.data.programa_contacto.programa.nombre;
    this.voceros = this.data.voceros.map(vocero => { return `${vocero.nombres} ${vocero.apellidos}` })
    this.muestras_registradas = this.data.muestrasRegistradas;
    this.muestras_enviadas = this.data.muestrasEnviadas;
    this.muestras_verificadas = this.data.muestrasVerificadas;
    this.lastBitacora = this.data.bitacoras.length - 1;
    this.observacion = this.data.bitacoras[this.lastBitacora].observacion

   }

   ngOnInit(): void {

    zip(
      this.typeNotes.getAll(),
      this.stages.getAll(),
      this.tiers.getAll()
    ).subscribe(([{ data: typeNotes }, { data: stages }, { data: tiers }]: any[]) => {
      this.tipoNota = typeNotes.find(n => n.id == this.data.tipoNota).name
      this.etapa = stages.find(n => n.id == this.data.tipoEtapa).name
      this.tier = tiers.find(n => n.id == this.data.tipoTier).name
    });

    this.dynamicSelectService.getListBySlug('comunicacion')
    .pipe(map(tipo => tipo['tipoAtributos']))
    .subscribe(response => {
      const tipoAtributos = response;
      const idTipoComunicacion = this.data.bitacoras[this.lastBitacora].idTipoComunicacion
      this.tipo_comunicacion = tipoAtributos.find(atrib => atrib.id == idTipoComunicacion).name
    })

    this.resultadoService.getByDPM(this.data.id)
    .subscribe( (response: resultadoResponseAPI[]) => {
        const impactos = response
        const plataformas = impactos.map( impacto => {
          const medioPlataforma = this.data.medioPlataformas.find( mp => mp.id == impacto.idMedioPlataforma)
          return {
            plataforma: medioPlataforma.plataforma_clasificacion.plataforma.descripcion,
            clasificacion: medioPlataforma.plataforma_clasificacion.descripcion,
            label: medioPlataforma.valor,
            fechaPublicada: impacto.fechaPublicacion,
            url: impacto.url
          }
        })
        this.plataformas = plataformas
     });
   }
   

}
